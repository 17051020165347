import {
  CarouselController,
  CarouselOptions,
} from "./controller/carousel-controller";
import { createAsyncStackTaggingLink } from "../../user-agent-fallbacks/async-stack-tagging";
import { initializeCarouselController } from "./controller/initialize-carousel-controller";

export type CarouselAdditionInitializationBlock = (
  carouselRootElement: HTMLElement,
  controller: CarouselController
) => void;

const carouselHandles = new Map<HTMLElement, CarouselController>();
const carouselRegistrationHandlers =
  [] as CarouselAdditionInitializationBlock[];

export const initializeAndRegisterCarouselController = (
  carouselRootElement: HTMLElement,
  options?: CarouselOptions
) => {
  const controller = initializeCarouselController(carouselRootElement, options);
  carouselHandles.set(carouselRootElement, controller);

  const runLinked = createAsyncStackTaggingLink(
    "schedule initialization of handlers for newly registered carousel element"
  );

  for (const registrationHandler of carouselRegistrationHandlers) {
    requestAnimationFrame(() =>
      runLinked(registrationHandler, carouselRootElement, controller)
    );
  }

  return controller;
};

/**
 * Runs the given block for each registered {@link CarouselController}.
 *
 * This function accounts both already-registered elements and elements that will be registered in the future.
 */
export const forEachRegisteredCarouselController = (
  block: CarouselAdditionInitializationBlock
) => {
  carouselRegistrationHandlers.push(block);

  const runLinked = createAsyncStackTaggingLink(
    "schedule initialization of newly registered handler for existing carousel elements"
  );

  for (const [
    carouselElement,
    carouselController,
  ] of carouselHandles.entries()) {
    if (!carouselElement.isConnected) {
      continue;
    }

    requestAnimationFrame(() =>
      runLinked(block, carouselElement, carouselController)
    );
  }
};
