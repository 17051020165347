/**
 * A typed version of {@link EventTarget}.
 */
export interface TypedEventTarget<
  EventTypeMap extends { [KeyType in keyof EventTypeMap]: Event }
> {
  addEventListener<
    TypeOfEventType extends MustBeStringType<keyof EventTypeMap>
  >(
    type: TypeOfEventType,
    handler: (event: EventTypeMap[TypeOfEventType]) => void,
    options?: boolean | AddEventListenerOptions | undefined
  ): void;

  removeEventListener<
    TypeOfEventType extends MustBeStringType<keyof EventTypeMap>
  >(
    type: TypeOfEventType,
    handler: (event: EventTypeMap[TypeOfEventType]) => void,
    options?: boolean | EventListenerOptions | undefined
  ): void;

  dispatchEvent(event: EventTypeMap[keyof EventTypeMap]): void;
}

export const createTypedEventTarget = <
  const EventTypeMap extends { [KeyType in keyof EventTypeMap]: Event }
>(): TypedEventTarget<EventTypeMap> => {
  const eventTarget = new EventTarget();
  return {
    addEventListener: (type, handler, options?) => {
      eventTarget.addEventListener(
        type,
        handler as (event: Event) => void,
        options
      );
    },
    removeEventListener: (type, handler, options?) => {
      eventTarget.removeEventListener(
        type,
        handler as (event: Event) => void,
        options
      );
    },
    dispatchEvent: (event) => {
      eventTarget.dispatchEvent(event);
    },
  };
};

type MustBeStringType<T> = T extends string ? T : never;
