/**
 * Selects all elements that are not present in nested carousels.
 */
export const currentCarouselQuerySelectorAll = <Type extends HTMLElement>(
  rootElement: HTMLElement,
  query: string
): Type[] => {
  const nestedCarousels = Array.from(
    rootElement.querySelectorAll<HTMLElement>(`.carousel`)
  );
  const carouselElements = Array.from(
    rootElement.querySelectorAll<Type>(query)
  );

  if (nestedCarousels.length === 0) {
    return carouselElements;
  }

  nestedCarousels.flatMap((carousel) =>
    Array.from(carousel.querySelectorAll<Type>(query))
  );

  const nestedCarouselElements: Set<Type> = new Set(
    nestedCarousels.flatMap((carousel) =>
      Array.from(carousel.querySelectorAll<Type>(query))
    )
  );

  return carouselElements.filter(
    (element) => !nestedCarouselElements.has(element)
  );
};
