import {
  CarouselController,
  CarouselControllerEventMap,
  CarouselOptions,
} from "./carousel-controller";
import { initializeSliderElementsList } from "../slider/slider-elements-list";
import { initializeSliderCurrentElementWatcher } from "../slider/slider-current-element";
import { createTypedEventTarget } from "../../../typed-event-target";
import { CarouselControllerWillChangeSlideEvent } from "./carousel-controller-will-change-slide-event";
import { readDesiredCarouselDirection } from "../carousel-direction";

const initializeOptions = (options?: CarouselOptions): CarouselOptions => ({
  loop: options?.loop ?? true,
});

export const initializeCarouselController = (
  rootElement: HTMLElement,
  options?: CarouselOptions
): CarouselController => {
  const eventTarget = createTypedEventTarget<CarouselControllerEventMap>();

  const sliderRootElement = findCarouselSliderRootElement(rootElement);
  const elementList = initializeSliderElementsList(
    sliderRootElement,
    eventTarget.dispatchEvent
  );
  const currentElementWatcher = initializeSliderCurrentElementWatcher(
    sliderRootElement,
    (event) => {
      eventTarget.dispatchEvent(event);
      currentElementWatcher.setScrollOffset(event.scrollOffset);
    }
  );

  return {
    ...eventTarget,
    options: initializeOptions(options),
    getCurrentIndex: () => currentElementWatcher.lastKnownSliderIndex(),
    getNumberOfSlides: () => elementList.getNumberOfElements(),
    getElementOfNthSlide: (index) => elementList.getNthElement(index),
    slideTo: (nextIndex) => {
      const direction = readDesiredCarouselDirection(sliderRootElement);
      const slideElement = elementList.getNthElement(nextIndex);
      const event = new CarouselControllerWillChangeSlideEvent(nextIndex, 0);
      eventTarget.dispatchEvent(event);
      currentElementWatcher.setScrollOffset(event.scrollOffset);
      const targetInline =
        direction === "inline"
          ? slideElement.offsetLeft - event.scrollOffset
          : slideElement.offsetLeft;
      const targetBlock =
        direction === "block"
          ? slideElement.offsetTop - event.scrollOffset
          : slideElement.offsetTop;
      // Google chrome 120 doesn't pick up the scroll behavior set by the css
      // property, so we explicitly ask for smooth scrolling here.
      // The issue does not exist on Chromium 122.
      //
      // The spec explicitly includes the APIs for programmatically invoked
      // scrolling:
      // https://drafts.csswg.org/css-overflow/#smooth-scrolling
      sliderRootElement.scrollTo({
        top: targetBlock,
        left: targetInline,
        behavior: "smooth",
      });
    },
  };
};

export const findCarouselSliderRootElement = (
  carouselRootElement: HTMLElement
) => {
  const element = carouselRootElement.querySelector<HTMLElement>(
    ":scope .carousel-inner"
  );
  if (element === null) {
    throw new Error("Could not find carousel slider (.carousel-inner)");
  }
  return element;
};
