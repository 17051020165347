/**
 * The number of slides inside the slide has changed.
 */
export class CarouselSliderItemNumberChangeEvent extends Event {
  public static readonly KEY = "CarouselSliderItemNumberChangeEvent" as const;

  constructor(public readonly numberOfItems: number) {
    super(CarouselSliderItemNumberChangeEvent.KEY);
  }
}
