type CreateAsyncStackTaggingLinkFunctionType = (
  name?: string
) => <ArgsType extends unknown[], ReturnType>(
  block: (...args: ArgsType) => ReturnType,
  ...args: ArgsType
) => ReturnType;

/**
 * Create a new task object.
 * Use its `run` method to associate the current stack trace with the task object,
 * if the user agent is able to understand such links.
 *
 * This aids debugging in compatible UAs.
 * The fallback for non-compatible UAs is handled automatically.
 *
 * @see https://developer.chrome.com/docs/devtools/console/api/#createtask
 * @see https://developer.chrome.com/blog/devtools-modern-web-debugging/#linked-stack-traces
 */
export const createAsyncStackTaggingLink: CreateAsyncStackTaggingLinkFunctionType =
  // eslint-disable-next-line no-console
  console && "createTask" in console && typeof console.createTask === "function"
    ? (name?) => {
        // eslint-disable-next-line no-console
        const task = console.createTask(name ?? "unnamed");

        return (block, ...args) => task.run(() => block(...args));
      }
    : () =>
        (block, ...args) =>
          block(...args);

declare global {
  interface Console {
    /**
     * Returns a Task instance that associates the current stack trace with the created task object.
     * You can later use this task object to run a function.
     * The task.run(f) executes an arbitrary payload and forwards the return value back to the caller.
     *
     * The task forms a link between the creation context and the context of the async function.
     * This link lets DevTools show better stack traces for async operations.
     * For more information, see
     * {@link https://developer.chrome.com/blog/devtools-modern-web-debugging/#linked-stack-traces Linked Stack Traces}.
     *
     * @see https://developer.chrome.com/docs/devtools/console/api/#createtask
     * @see https://docs.google.com/document/d/1Yv8qi-1gRuFB8EaQGb79n5GGPtm_ZRrpaigKNsf5g_0
     */
    createTask(name: string): Task;
  }
}

interface Task {
  /**
   * When running the task, the given workload callback is executed. The task's
   * starting and stopping boundaries are the callback boundary. When an error
   * is thrown while running the callback, the task will be automatically
   * finished. The semantics are just JS execution semantics: this is equivalent
   * to changing `f()` to `task.run(f)`.
   *
   * @see https://developer.chrome.com/blog/devtools-modern-web-debugging/#linked-stack-traces
   * @see https://docs.google.com/document/d/1Yv8qi-1gRuFB8EaQGb79n5GGPtm_ZRrpaigKNsf5g_0
   */
  run<T>(f: () => T): T;
}
