import {
  CarouselDirection,
  readDesiredCarouselDirection,
} from "../carousel-direction";
import { CarouselSliderSlideChangeEvent } from "./carousel-slider-slide-change-event";
import { fetchCarouselSliderItems } from "./slider-elements-list";

export const initializeSliderCurrentElementWatcher = (
  sliderRootElement: HTMLElement,
  handleSlideChange: (event: CarouselSliderSlideChangeEvent) => void
) => {
  const sliderDirection = readDesiredCarouselDirection(sliderRootElement);
  let lastKnownSliderIndex = 0;
  let scrollOffset = 0;

  const updateSliderIndex = () => {
    const currentScrollPosition =
      getCurrentSliderScrollPosition(sliderRootElement, sliderDirection) +
      scrollOffset;

    let index = -1;
    for (const slideElement of fetchCarouselSliderItems(sliderRootElement)) {
      index += 1;
      const slideScroll =
        sliderDirection === "inline"
          ? slideElement.offsetLeft
          : slideElement.offsetTop;
      const slideSize =
        sliderDirection === "inline"
          ? slideElement.offsetWidth
          : slideElement.offsetHeight;
      const scrollOfMiddle = slideScroll + slideSize / 2;

      const currentScrollIsPastMiddle = currentScrollPosition > scrollOfMiddle;

      if (currentScrollIsPastMiddle) {
        continue;
      }

      break;
    }

    index = Math.max(0, index);

    if (index === lastKnownSliderIndex) {
      return;
    }

    lastKnownSliderIndex = index;
    handleSlideChange(new CarouselSliderSlideChangeEvent(index, scrollOffset));
  };

  sliderRootElement.addEventListener("scroll", updateSliderIndex);

  return {
    lastKnownSliderIndex: () => lastKnownSliderIndex,
    setScrollOffset: (newScrollOffset: number) => {
      scrollOffset = newScrollOffset;
    },
  };
};

const getCurrentSliderScrollPosition = (
  element: HTMLElement,
  direction: CarouselDirection
) => {
  switch (direction) {
    case "inline":
      return element.scrollLeft;
    case "block":
      return element.scrollTop;
    default:
      break;
  }

  throw new TypeError(
    `Value '${direction}' is invalid for type "inline" | "block"`
  );
};
