import { CarouselSliderItemNumberChangeEvent } from "./carousel-slider-item-number-change-event";
import { currentCarouselQuerySelectorAll } from "../selector/current-selector";

export const CAROUSEL_ITEM_CLASS_NAME = "carousel-item";

export const initializeSliderElementsList = (
  sliderRootElement: HTMLElement,
  handleItemNumberChangeEvent: (
    event: CarouselSliderItemNumberChangeEvent
  ) => void
) => {
  let elements = fetchCarouselSliderItems(sliderRootElement);

  const observer = new MutationObserver((mutationList) => {
    for (const mutation of mutationList) {
      if (
        mutation.addedNodes.length !== 0 ||
        mutation.removedNodes.length !== 0
      ) {
        const previousNumberOfElements = elements.length;
        elements = fetchCarouselSliderItems(sliderRootElement);
        if (previousNumberOfElements !== elements.length) {
          handleItemNumberChangeEvent(
            new CarouselSliderItemNumberChangeEvent(elements.length)
          );
        }
      }
    }
  });

  observer.observe(sliderRootElement, { childList: true });

  return {
    getNumberOfElements: () => elements.length,
    getNthElement: (index: number) => {
      if (index < 0 || index >= elements.length) {
        throw new Error(
          `Index ${index} is out-of-bounds for zero-based element list with ${elements.length} elements.`
        );
      }

      return elements[index];
    },
  };
};

export const fetchCarouselSliderItems = (sliderRootElement: HTMLElement) =>
  currentCarouselQuerySelectorAll(
    sliderRootElement,
    `:scope > .${CAROUSEL_ITEM_CLASS_NAME}`
  );
