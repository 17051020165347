export type CarouselDirection = "inline" | "block";

/**
 * The `data-carousel-direction` attribute
 */
const CAROUSEL_DIRECTION_DATASET_KEY = "carouselDirection";

const CAROUSEL_DIRECTION_DEFAULT_VALUE =
  "inline" as const satisfies CarouselDirection;

export const readDesiredCarouselDirection = (
  element: HTMLElement
): CarouselDirection => {
  const dataSetValue =
    CAROUSEL_DIRECTION_DATASET_KEY in element.dataset &&
    element.dataset[CAROUSEL_DIRECTION_DATASET_KEY];

  return dataSetValue !== "inline" && dataSetValue !== "block"
    ? CAROUSEL_DIRECTION_DEFAULT_VALUE
    : dataSetValue;
};
